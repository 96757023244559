import { BlockWithLabel, BlockWithLabelAndMdf, MdfsSeparated } from 'api/mdfBlocks/types';
import useCustomMemo from 'hooks/useCustomMemo';
import { Mdf } from 'types/graphqlTypes';
import { getDefaultValues } from 'utils/mdf/utils';

export const getBlocksWithMdf = (
  blocks: BlockWithLabel[],
  mdfs: Mdf[],
  mdfsSeparated: MdfsSeparated,
) => {
  const mdfsMap = mdfs.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {} as { [key: string]: Mdf });

  return blocks.map((block) => {
    const { mdfId } = block;
    const mdf = mdfsMap[mdfId];

    if (mdf) {
      const defaultValues = getDefaultValues(block.metadata, mdf, mdfsSeparated.subTypes);
      return { ...block, mdf, metadata: { ...block.metadata, ...defaultValues } };
    }

    return { ...block, mdf } as BlockWithLabelAndMdf;
  });
};

const useGetBlocksWithMdf = (
  blocks: BlockWithLabel[],
  mdfs: Mdf[],
  mdfsSeparated: MdfsSeparated,
) => {
  const blocksWithMdf = useCustomMemo(
    () => getBlocksWithMdf(blocks, mdfs, mdfsSeparated),
    [blocks, mdfs, mdfsSeparated],
  );
  return { blocksWithMdf };
};

export default useGetBlocksWithMdf;
