import { MdfsSeparated } from 'api/mdfBlocks/types';
import useCustomMemo from 'hooks/useCustomMemo';
import { Order, OrderWithMdf } from 'types/forms/forms';
import { Mdf } from 'types/graphqlTypes';
import { getDefaultValues } from 'utils/mdf/utils';

export const getOrdersWithMdf = (orders: Order[], mdfs: Mdf[], mdfsSeparated: MdfsSeparated) => {
  const mdfsMap = mdfs.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {} as { [key: string]: Mdf });

  return orders.map((order) => {
    const { mdfId } = order;
    const mdf = mdfsMap[mdfId];

    if (mdf) {
      const defaultValues = getDefaultValues(order.metadata, mdf, mdfsSeparated.subTypes);
      return { ...order, mdf, metadata: { ...order.metadata, ...defaultValues } };
    }

    return { ...order, mdf } as OrderWithMdf;
  });
};

const useGetOrdersWithMdf = (orders: Order[], mdfs: Mdf[], mdfsSeparated: MdfsSeparated) => {
  const ordersWithMdf = useCustomMemo(
    () => getOrdersWithMdf(orders, mdfs, mdfsSeparated),
    [orders, mdfs, mdfsSeparated],
  );

  return { ordersWithMdf };
};

export default useGetOrdersWithMdf;
