import { useCallback, useEffect, useMemo, useState } from 'react';
import { ScopeProvider } from 'jotai-molecules';

import { ReactComponent as LeftArrow } from 'assets/icons/systemicons/disclosurearrow_left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/systemicons/disclosurearrow_right.svg';
import { isValidTab, SidepanelProps } from 'features/sidepanel/Sidepanel';
import { HStack } from 'layouts/box/Box';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';

import { SplitScope, useSplitViewMolecule } from './store';

import { CollapseLine, SplitPane } from './styled';

type PaneProps =
  | {
      pane1: React.ReactElement;
      pane2: React.ReactElement;
    }
  | {
      pane1: React.ReactElement;
      pane2: null;
    }
  | {
      pane1: null;
      pane2: React.ReactElement;
    };

const arrowStyle = { margin: 'auto' };

export function LeftCollapsibleSplitPane({ pane1, pane2 }: PaneProps) {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = useCallback(() => setCollapsed((pre) => !pre), []);

  const pane2WithCollapse = useMemo(() => {
    return (
      <HStack width="100%" height="100%">
        <CollapseLine onClick={toggleCollapse}>
          {collapsed ? <RightArrow style={arrowStyle} /> : <LeftArrow style={arrowStyle} />}
        </CollapseLine>
        {pane2}
      </HStack>
    );
  }, [collapsed, pane2, toggleCollapse]);

  return (
    // @ts-expect-error: TS2322 because the library hasn't fixed it yet https://github.com/tomkp/react-split-pane/issues/826
    <SplitPane
      style={{ position: 'relative' }}
      split="vertical"
      pane1Style={{
        minWidth: collapsed ? '0%' : '340px',
        maxWidth: collapsed ? '0%' : 'calc(100% - 560px)',
      }}
      pane2Style={{
        minWidth: collapsed ? '100%' : '560px',
        maxWidth: collapsed ? '100%' : 'calc(100% - 340px)',
        display: 'flex',
        flex: 1,
      }}
      defaultSize={collapsed ? '100%' : '323px'}
      primary="first"
      allowResize={!!(pane1 && pane2)}
    >
      {pane1}
      {pane2WithCollapse}
    </SplitPane>
  );
}

type RightCollapsiblePaneProps = PaneProps &
  SidepanelProps & {
    pane2hidden?: boolean;
    toggle?: boolean;
    setToggle?: (value: boolean) => void;
  };

function RightCollapsibleSplitView({
  pane1,
  pane2,
  pane2hidden,
  toggle,
  setToggle,
  sidepanelTab,
  sidepanelSelectedBlockId,
  sidepanelShowComment,
}: Readonly<Omit<RightCollapsiblePaneProps, 'view'>>) {
  const { useShowSidePanel, useSelectedBlockId, useSidepanelTab, useShowComment } =
    useSplitViewMolecule();
  const [showPanel, setShowPanel] = useShowSidePanel();
  const [, setSidepanelTab] = useSidepanelTab();
  const [, setSelectedBlockId] = useSelectedBlockId();
  const [, setShowComment] = useShowComment();

  const showSidepanel = toggle ?? showPanel;
  const setShowSidepanel = setToggle ?? setShowPanel;

  const toggleCollapse = useCallback(
    () => setShowSidepanel(!showSidepanel),
    [setShowSidepanel, showSidepanel],
  );

  useEffect(() => {
    if (sidepanelTab && isValidTab(sidepanelTab)) {
      setShowSidepanel(true);
      setSidepanelTab(sidepanelTab);
    }
    if (sidepanelSelectedBlockId) {
      setSelectedBlockId(sidepanelSelectedBlockId);
    }
    if (sidepanelShowComment) {
      setShowComment(true);
    }
  }, [sidepanelSelectedBlockId, sidepanelShowComment, sidepanelTab]);

  const pane1WithCollapse = useMemo(() => {
    return (
      <HStack width="100%" height="100%">
        <HStack height="100%" width="calc(100% - 16px)">
          {pane1}
        </HStack>
        <CollapseLine onClick={toggleCollapse}>
          {showSidepanel ? <RightArrow style={arrowStyle} /> : <LeftArrow style={arrowStyle} />}
        </CollapseLine>
      </HStack>
    );
  }, [showSidepanel, pane1, toggleCollapse]);

  if (pane2hidden || !pane2) return pane1;

  return (
    /* @ts-expect-error: TS2322 */
    <SplitPane
      style={{ position: 'relative', height: '100%' }}
      split="vertical"
      pane1Style={{
        minWidth: showSidepanel ? '50%' : '100%',
        maxWidth: showSidepanel ? '65%' : '100%',
        display: 'flex',
        flex: 1,
      }}
      pane2Style={{
        minWidth: showSidepanel ? '35%' : '0%',
        maxWidth: showSidepanel ? '50%' : '0%',
      }}
      primary="second"
      allowResize={!!(pane1 && pane2)}
    >
      {pane1WithCollapse}
      {pane2}
    </SplitPane>
  );
}

export function RightCollapsiblePane({
  view = 'story',
  index = 0,
  ...rest
}: RightCollapsiblePaneProps & {
  view?: 'preview' | 'story' | 'rundown';
  index?: number;
}) {
  return (
    <ScopeProvider scope={SplitScope} value={`${view}:${index}`}>
      <RightCollapsibleSplitView {...rest} />
    </ScopeProvider>
  );
}

export function RightCollapsiblePaneForStory(
  props: RightCollapsiblePaneProps & { view?: 'preview' | 'story' | 'rundown' },
) {
  const { usePaneIndexValue, useShowRightPanel } = useStoryPaneMolecule();
  const paneIndex = usePaneIndexValue();
  const [toggle, setToggle] = useShowRightPanel();

  return (
    <RightCollapsiblePane {...props} index={paneIndex} toggle={toggle} setToggle={setToggle} />
  );
}
