import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import LOCK_MEMBER from 'operations/mutations/lockMember';
import { LockMemberInput, MemberType } from 'types/graphqlTypes';
import { resetLockToken, setLockToken } from 'utils/lock/lockTokenV2';
import useLogger from 'utils/useLogger';

type LockMemberReturnType = {
  lockMember: MemberType;
};

type LockMemberInputType = {
  input: LockMemberInput;
};

const useLockMember = () => {
  const logger = useLogger('use lock member');
  const [lockMemberMutation] = useMutation<LockMemberReturnType, LockMemberInputType>(LOCK_MEMBER);

  const lock = useCallback(
    async (mId: string, userId: string, scope?: string) => {
      const userLockToken = scope ? setLockToken(scope, userId) : userId;
      const input = {
        mId,
        userId: userLockToken,
      };

      try {
        const result = await lockMemberMutation({
          variables: { input },
        });

        return result;
      } catch (e) {
        if (scope) resetLockToken(scope);
        logger.log(e);
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lockMemberMutation],
  );

  return [lock] as const;
};

export default useLockMember;
