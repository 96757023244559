import { useCallback, useEffect, useMemo } from 'react';

import { isOlderSlateValue, migrateValue } from 'components/editor/utils';
import useCreateFolder from 'hooks/useCreateFolder';
import useDeleteFolder from 'hooks/useDeleteFolder';
import useDeleteTemplate from 'hooks/useDeleteTemplate';
import useGetContentTemplateFolders from 'hooks/useGetContentTemplateFolders';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useSaveTemplate from 'hooks/useSaveTemplate';
import useTextStorage from 'hooks/useTextStorage';
import useUpdateSettings from 'hooks/useUpdateSettings';
import { EditorValue, Template } from 'types';
import { filter, IgnoredTemplateKeys } from 'utils/metadata';

import { useInstanceMolecule } from '../store/instance';

import { UpdateInputParameters } from './useInstanceCore';

const useInstanceTemplate = (
  saveAll: (params: UpdateInputParameters) => Promise<void>,
  cancelDebounce: () => void,
  resetEditorValue: (newValue: EditorValue) => void,
  handleLockInstance: () => Promise<string | undefined>,
) => {
  const { editorValueRef, useTemplateKey, useInstanceValue, useMetadata } = useInstanceMolecule();

  const [templateKey, setTemplateKey] = useTemplateKey();

  const instance = useInstanceValue();
  const [metadata] = useMetadata();

  const { getPlatformVariant } = useGetPlatforms(instance?.mPublishingAt ?? new Date());

  const [defaultTemplateKey, defaultTemplateRefId] = useMemo(() => {
    if (!instance) return [undefined, undefined];
    const variant = getPlatformVariant(instance);
    return [variant?.defaultTemplateKey, variant?.defaultTemplate];
  }, [getPlatformVariant, instance]);

  const updateSettings = useUpdateSettings();
  const [deleteTemplate] = useDeleteTemplate();
  const [saveTemplate] = useSaveTemplate(editorValueRef);
  const [createFolder] = useCreateFolder();
  const [deleteFolder] = useDeleteFolder();

  const { loading, data, refetch } = useTextStorage(templateKey);
  const folders = useGetContentTemplateFolders(
    instance?.mProperties?.platform ?? '',
    instance?.mProperties?.platformKind ?? '',
  );

  const onSelectTemplate = useCallback(
    ({ mContentKey }: { mContentKey: string }) => {
      if (templateKey === mContentKey) {
        refetch();
      } else {
        setTemplateKey(mContentKey);
      }
    },
    [refetch, setTemplateKey, templateKey],
  );

  const onTemplateInsert = useCallback(
    async (templateData: EditorValue) => {
      if (loading || !templateData || !instance || !templateKey) return;

      const lockedResponse = instance.locked || (await handleLockInstance());

      if (!lockedResponse) return;

      const content = isOlderSlateValue(templateData)
        ? (migrateValue(templateData, {}) as EditorValue)
        : (data as unknown as EditorValue);

      const params = {
        content,
        instance: {
          ...instance,
          ...(content?.metadata && { mMetadata: content.metadata }),
        },
        version: 'template',
        metadata: filter(content?.metadata, { excludeKeys: IgnoredTemplateKeys, ignoreNull: true }),
        audit: { source: 'useInstanceTemplate:onTemplateInsert' },
      };
      setTemplateKey('');
      cancelDebounce();
      await saveAll(params);
      resetEditorValue(content);
    },
    [
      cancelDebounce,
      data,
      handleLockInstance,
      instance,
      loading,
      resetEditorValue,
      saveAll,
      setTemplateKey,
      templateKey,
    ],
  );

  const onSaveTemplate = useCallback(
    async (
      folderId: string,
      templateTitle: string,
      description?: string,
      overwriteData?: Template,
    ) => {
      const newMProperties = {
        __typename: 'PlatformType',
        platform: instance?.mProperties?.platform,
        ...(instance?.mProperties?.platformKind && {
          platformKind: instance?.mProperties?.platformKind,
        }),
      };

      await saveTemplate({
        folderId,
        templateTitle,
        description,
        overwriteData,
        metadata,
        mProperties: newMProperties,
      });
    },
    [instance?.mProperties?.platform, instance?.mProperties?.platformKind, metadata, saveTemplate],
  );

  const onSetDefaultTemplate = useCallback(
    async (defaultTemplateId?: string) => {
      if (!defaultTemplateKey) return;
      await updateSettings({
        mId: 'settings',
        mRefId: 'general',
        mMetaData: [{ key: defaultTemplateKey, value: defaultTemplateId }],
      });
    },
    [updateSettings, defaultTemplateKey],
  );

  const onCreateFolder = useCallback(
    async (title: string, parentId: string) => {
      const updatedMProperties = {
        __typename: 'PlatformType',
        platform: instance?.mProperties?.platform,
        ...(instance?.mProperties?.platformKind && {
          platformKind: instance?.mProperties?.platformKind,
        }),
        pinned: false,
      };
      await createFolder(title, parentId, updatedMProperties);
    },
    [createFolder, instance?.mProperties?.platform, instance?.mProperties?.platformKind],
  );

  const onDeleteTemplate = useCallback(
    async (templateId: string, templateRefId: string) => {
      await deleteTemplate(templateId, templateRefId);
    },
    [deleteTemplate],
  );

  const onDeleteFolder = useCallback(
    async (folderId: string, folderRefId: string) => {
      await deleteFolder(folderId, folderRefId);
    },
    [deleteFolder],
  );

  useEffect(() => {
    void onTemplateInsert(data as unknown as EditorValue);
  }, [data, onTemplateInsert]);

  return {
    loading,
    onSelectTemplate,
    onSetDefaultTemplate,
    onSaveTemplate,
    onCreateFolder,
    onDeleteFolder,
    folders,
    defaultTemplateRefId,
    onDeleteTemplate,
  };
};

export default useInstanceTemplate;
