import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import UNLOCK_MEMBER from 'operations/mutations/unlockMember';
import { EditorValue } from 'types/editor';
import { MemberType, UnlockMemberInput } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

type UnlockMemberReturnType = {
  lockMember: MemberType;
};

type UnlockMemberInputType = {
  input: UnlockMemberInput;
};

const useUnLockMember = () => {
  const logger = useLogger('use unlock member');
  const [unlockMemberMutation] = useMutation<UnlockMemberReturnType, UnlockMemberInputType>(
    UNLOCK_MEMBER,
  );

  const unlock = useCallback(
    async (mId: string, content?: EditorValue | null, isCancelEvent?: boolean) => {
      const input = {
        mId,
        content: JSON.stringify(content ?? {}),
        ...(isCancelEvent && { isCancelEvent }),
      };

      try {
        await unlockMemberMutation({
          variables: { input },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        logger.log(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unlockMemberMutation],
  );

  return [unlock] as const;
};

export default useUnLockMember;
