import gql from 'graphql-tag';

export default gql`
  mutation editNote($input: UpdateNoteInput) {
    editNote(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mDescription
      locked
      mContentKey
      mDefaultContentKey
      mUpdatedAt
      mCreatedAt
      mUpdatedById
      mProperties {
        __typename
        ... on NoteProperties {
          pinned
          labelId
        }
      }
    }
  }
`;
