import isEmpty from 'lodash/isEmpty';

import { isMiniMember } from 'components/mdfEditor/fields/relation/relation-utils';
import { CommandToolbarProps } from 'features/command/command-types';
import { isObject } from 'types';
import { Metadata, SearchMetadata } from 'types/forms/forms';
import {
  AggregateByInput,
  FieldValue,
  SearchItemInput,
  SearchItemTypeEnum,
} from 'types/graphqlTypes';

import { SearchableKeys } from './types';

const replacementTypes: Partial<{ [key in SearchItemTypeEnum]: SearchItemTypeEnum }> = {
  [SearchItemTypeEnum.story]: SearchItemTypeEnum.res_story,
  [SearchItemTypeEnum.pitch]: SearchItemTypeEnum.res_pitch,
  [SearchItemTypeEnum.archived_story]: SearchItemTypeEnum.archived_res_story,
  [SearchItemTypeEnum.archived_pitch]: SearchItemTypeEnum.archived_res_pitch,
};

export interface DateRange {
  startDate: string;
  endDate: string | null;
}

/**
 * Currently only date type can be an object in Metadata values.
 */
export const isDateRange = (obj: FieldValue): obj is DateRange => {
  if (obj === null || !isObject(obj) || Array.isArray(obj)) return false;
  return true;
};

// TODO - instead of guessing the values, we should use the schemas to resolve
// the field types.
const process = (metadata: Metadata): SearchMetadata => {
  const copy: SearchMetadata = {};
  Object.entries(metadata).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value.trim().length > 0) copy[key] = value;
    } else if (isDateRange(value)) {
      copy[key] = {
        from: value.startDate,
        to: value.endDate ?? value.startDate,
      };
    } else if (Array.isArray(value) && value.length > 0) {
      if (isMiniMember(value[0])) {
        copy[key] = value[0].id;
      } else {
        copy[key] = value;
      }
    } else if (value !== null && value !== undefined) {
      copy[key] = value;
    }
  });
  return copy;
};

const getMDFfilter = (forceMTypes: SearchItemTypeEnum[], metadataFilter: Metadata) => {
  if (isEmpty(metadataFilter)) return undefined;
  if (forceMTypes.length > 0) {
    const mdFilter: Record<string, SearchMetadata> = {};
    for (const mType of forceMTypes) {
      mdFilter[mType] = process(metadataFilter);
    }
    return JSON.stringify(mdFilter);
  }
  return undefined;
};

export const convertToRestrictedOnlyTypes = (
  mTypes: SearchItemTypeEnum[],
): SearchItemTypeEnum[] => {
  return mTypes.map((type) => replacementTypes[type] ?? type);
};

export const getInput = (
  searchString: string,
  searchableKey: SearchableKeys | undefined,
  state: CommandToolbarProps,
  perPagelimit: number,
  metadataFilter: Metadata,
  mdfId: string | undefined,
  forceMetadataMTypes: SearchItemTypeEnum[] = [],
  mIds: string[] | undefined = [],
  aggregations?: AggregateByInput[],
): SearchItemInput => {
  const {
    rangeBy,
    semanticSearch,
    statusFilter,
    mTypes,
    platformTypes,
    sortBy,
    order,
    assignedIds,
    createdByIds,
    isScheduled,
    matchAllAssignees,
    showRestricted,
    restrictedItemsOnly,
  } = state;
  const derivedSearchString = searchString.length > 0 ? searchString : undefined;
  return {
    perPagelimit,
    mdfId,
    mTypes: restrictedItemsOnly ? convertToRestrictedOnlyTypes(mTypes) : mTypes,
    rangeBy: rangeBy ?? undefined,
    searchString: !searchableKey ? derivedSearchString : undefined,
    mStates: statusFilter.length ? statusFilter : undefined,
    orderBy: sortBy !== 'best' ? { [sortBy]: order } : undefined,
    assignedMemberIds: !matchAllAssignees && assignedIds.length ? assignedIds : undefined,
    assignedMemberIdsMustOccur: matchAllAssignees && assignedIds.length ? assignedIds : undefined,
    createdByIds: createdByIds.length ? createdByIds : undefined,
    metaDataFilter: forceMetadataMTypes.length > 0 ? '{}' : JSON.stringify(process(metadataFilter)),
    metaDataFilterByType:
      forceMetadataMTypes.length > 0
        ? getMDFfilter(forceMetadataMTypes, metadataFilter)
        : undefined,
    platformTypes: platformTypes?.length ? platformTypes : undefined,
    semanticSearch: semanticSearch === true ? true : undefined,
    ...(searchableKey && { [searchableKey]: derivedSearchString }),
    isScheduled,
    showRestricted: showRestricted || restrictedItemsOnly,
    ...(mIds.length && { mIds }),
    aggregateBy: aggregations?.length ? aggregations : undefined,
  };
};
