import { useMutation } from '@apollo/client';
import UNLOCK_INSTANCE from 'operations/mutations/unlockInstance';
import useLogger from 'utils/useLogger';

const useUnlockInstance = () => {
  const logger = useLogger('use unlock instance');
  const [unlockInstanceMutation] = useMutation(UNLOCK_INSTANCE);

  const unlock = async (instanceId, content) => {
    const input = {
      mId: instanceId,
    };

    if (content) input.content = JSON.stringify(content);

    try {
      await unlockInstanceMutation({
        variables: { input },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
    }
  };

  return [unlock];
};

export default useUnlockInstance;
