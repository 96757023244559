import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import isNull from 'lodash/isNull';

import { initialValues } from 'components/editor/constants';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import variants from 'components/editor/constants/types/editorVariants';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import { useNotesMolecule } from 'features/notes/store';
import useCreateAsset, { AssetInput } from 'hooks/useCreateAsset';
import useTextStorage from 'hooks/useTextStorage';
import { Asset, EditorValue, Note } from 'types';
import { getAssetData, getFileAssetData } from 'utils/assetData';
import { getUserIdFromLockedId } from 'utils/lock/lockTokenV2';
import useLogger from 'utils/useLogger';

import { useNoteMolecule } from '../store';

const DEBOUNCE_DELAY = 12000; // 12 seconds
const PERIODIC_SAVE_INTERVAL = 5 * 60 * 1000; // 5 minutes

type UpdateInput =
  | { type: ActionTypesEnum.CHANGE; payload: EditorValue }
  | {
      type: ActionTypesEnum.COMMIT_UPDATE;
      payload: EditorValue;
      commitFor: 'asset' | 'userInitiated';
    }
  | { type: ActionTypesEnum.CREATE_ASSET; payload: { asset: Asset } }
  | { type: ActionTypesEnum.ASSET_INSERT; payload: { file: File } };

const defaultContentValue = initialValues(variants.GENERAL, undefined, undefined, false);

const useNoteEditor = (note: Note | undefined, canUpdate: boolean) => {
  const { mRefId: noteId, mContentKey, mStoryId } = note ?? {};
  const { mId: currentUserId } = useContext(UserContext);

  const [skipFetching, setSkipFetching] = useState(false);

  const {
    data: s3Data,
    loading: contentLoading,
    refetch,
  } = useTextStorage(mContentKey ?? '', !mContentKey || skipFetching);

  const { restoreVersionFnRef } = useNotesMolecule();

  const {
    baseAtom,
    lockContentAtom,
    unlockNoteAtom,
    saveContentAtom,
    setNoteAtom,
    updateContentAtom,
    editorValueRef,
  } = useNoteMolecule();

  const [createAssetMutation] = useCreateAsset();
  const { errorToast } = useToast();
  const { log } = useLogger('useNoteEditor');

  const { isLocked, lockedBy, isLoading, isSaving, content } = useAtomValue(baseAtom);

  const setCurrentNote = useSetAtom(setNoteAtom);
  const lockNote = useSetAtom(lockContentAtom);
  const unlockNote = useSetAtom(unlockNoteAtom);
  const updateContent = useSetAtom(updateContentAtom);
  const saveContent = useSetAtom(saveContentAtom);

  const [shouldResetSelection, setShouldResetSelection] = useState(false);

  /** interval ref for debounced save */
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  /** interval ref for periodic save */
  const periodicSaveRef = useRef<NodeJS.Timeout | null>(null);
  /** ref to track if we unlocked note for restoring version content */
  const wasLockedForRestoreRef = useRef(false);
  /** ref to track if there are unsaved changes */
  const hasUnsavedChangesRef = useRef(false);

  const clearDebounce = useCallback(() => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
      saveTimeoutRef.current = null;
    }
  }, []);

  /** resets editor value and content state */
  const onResetEditorValue = useCallback(
    (newValue: EditorValue | null) => {
      if (newValue) {
        updateContent(newValue);
        editorValueRef.current = newValue;
      } else if (isNull(newValue)) {
        updateContent(null);
        editorValueRef.current = null;
      }
      setShouldResetSelection(true);
    },
    [editorValueRef, updateContent],
  );

  /** asset updates */
  const createAsset = useCallback(
    async (assetData: AssetInput) => {
      if (!mStoryId) return;

      const asset = getAssetData(mStoryId, assetData);
      try {
        const result = await createAssetMutation(mStoryId, asset, true);

        return result;
      } catch (error) {
        errorToast(error, 'There was an error trying to create asset');
        log(error);
        return null;
      }
    },
    [createAssetMutation, errorToast, log, mStoryId],
  );

  const onAssetInsert = useCallback(
    async (file: File) => {
      if (!mStoryId) return;

      const assetData = getFileAssetData(mStoryId, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
        src: '',
      };

      try {
        const result = await createAssetMutation(mStoryId, assetData, true);
        const { createAssets: assets } = result.data as { createAssets: Asset[] };
        if (assets?.[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        errorToast(e, 'There was an error trying to insert asset.');
        log(e);
      }

      return sourceData;
    },
    [createAssetMutation, errorToast, log, mStoryId],
  );

  /** editor interaction */
  const onForceUnlock = useCallback(async () => {
    if (!note?.mId) return;
    await unlockNote({
      content: null,
      cancelled: false,
      source: 'useNoteEditor:onForceUnlock',
      forceUnlock: true,
    });
    setSkipFetching(false);
    refetch();
  }, [refetch, note?.mId, unlockNote]);

  const onFocusEditor = useCallback(async () => {
    if (canUpdate && !isLocked && !isLoading && !isSaving && noteId) {
      setSkipFetching(false);

      try {
        await Promise.all([refetch(), lockNote(currentUserId)]);
      } catch (err) {
        log(err);
      }
    }
  }, [canUpdate, isLocked, isLoading, isSaving, noteId, refetch, lockNote, currentUserId, log]);

  const onCancel = useCallback(async () => {
    onResetEditorValue(s3Data ?? defaultContentValue);
    clearDebounce();

    await unlockNote({
      content: s3Data ?? defaultContentValue,
      cancelled: true,
      source: 'useNoteEditor:onCancel',
    });

    hasUnsavedChangesRef.current = false;
  }, [clearDebounce, onResetEditorValue, s3Data, unlockNote]);

  const onSave = useCallback(async () => {
    clearDebounce();

    await unlockNote({
      content: editorValueRef.current ?? defaultContentValue,
      cancelled: false,
      source: 'useNoteEditor:onSave',
    });

    setSkipFetching(true);
    hasUnsavedChangesRef.current = false;
  }, [clearDebounce, editorValueRef, unlockNote]);

  const onContentUpdate = (newContent: EditorValue | null) => {
    editorValueRef.current = newContent;
    hasUnsavedChangesRef.current = true;

    clearDebounce();

    /** trigger debounce */
    saveTimeoutRef.current = setTimeout(() => {
      if (hasUnsavedChangesRef.current) {
        saveContent({ content: newContent, silent: true }).catch(() => {});

        if (editorValueRef.current === newContent) hasUnsavedChangesRef.current = false;
      }
    }, DEBOUNCE_DELAY);
  };

  /** handles editor updates */
  const onEditorUpdate = (input: UpdateInput) => {
    const { type, payload } = input;

    switch (type) {
      case ActionTypesEnum.CHANGE:
        onContentUpdate(payload);
        break;

      case ActionTypesEnum.COMMIT_UPDATE:
        saveContent({ content: editorValueRef.current ?? defaultContentValue, silent: true }).catch(
          () => {},
        );
        hasUnsavedChangesRef.current = false;
        break;

      case ActionTypesEnum.CREATE_ASSET: {
        const { asset } = payload;
        return createAsset(asset);
      }

      case ActionTypesEnum.ASSET_INSERT: {
        const { file } = payload;
        return onAssetInsert(file);
      }

      default:
        return null;
    }
  };

  /** version restoring */
  const onCheckVersionRestorability = useCallback(async () => {
    if (lockedBy === currentUserId) return true;

    const lockedId = await lockNote(currentUserId);
    wasLockedForRestoreRef.current = true;

    return !!(lockedId && getUserIdFromLockedId(lockedId) === currentUserId);
  }, [currentUserId, lockNote, lockedBy]);

  restoreVersionFnRef.current = useCallback(
    async (newContent: EditorValue) => {
      clearDebounce();

      const restorable = await onCheckVersionRestorability();
      if (!restorable) return;

      if (wasLockedForRestoreRef.current) {
        await unlockNote({
          content: newContent,
          cancelled: false,
          source: 'useNoteEditor:onRestoreVersion',
        });
        wasLockedForRestoreRef.current = false;
      } else {
        await saveContent({ content: newContent });
      }
      onResetEditorValue(newContent);
    },
    [clearDebounce, onCheckVersionRestorability, onResetEditorValue, saveContent, unlockNote],
  );

  /** sync states */
  useEffect(() => {
    /** updates content state if there's a change in s3 content */
    onResetEditorValue(s3Data);
  }, [onResetEditorValue, s3Data]);

  useEffect(() => {
    /** refetches content when lock state changes */
    if (isLocked && !note?.locked) {
      setSkipFetching(false);
      refetch();
    }
  }, [isLocked, refetch, note?.locked]);

  useEffect(() => {
    /** saves updated note to state */
    setCurrentNote(note ?? null).catch(() => {});
  }, [setCurrentNote, note]);

  useEffect(() => {
    /** resets editor selection when lock state changes */
    setShouldResetSelection(true);
  }, [note?.locked]);

  useEffect(() => {
    /** saves every 5 minutes with latest content */
    periodicSaveRef.current = setInterval(() => {
      if (hasUnsavedChangesRef.current) {
        clearDebounce();

        saveContent({ content: editorValueRef.current, silent: true }).catch(() => {});

        hasUnsavedChangesRef.current = false;
      }
    }, PERIODIC_SAVE_INTERVAL);

    return () => {
      if (periodicSaveRef.current) {
        clearInterval(periodicSaveRef.current);
      }
    };
  }, [clearDebounce, editorValueRef, saveContent]);

  useEffect(
    /** clears local state if note is changed or view unmounted */
    () => () => {
      clearDebounce();
      setSkipFetching(false);
    },
    [clearDebounce, noteId],
  );

  return {
    onFocusEditor,
    onForceUnlock,
    onEditorUpdate,
    onRestoreVersion: restoreVersionFnRef.current,
    onCancel,
    onSave,
    content,
    isLoading: isLoading || contentLoading,
    isLocked,
    lockedBy,
    isSaving,
    shouldResetSelection,
  };
};

export default useNoteEditor;
