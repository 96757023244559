import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

function useCustomMemo<T>(getValue: () => T, deps: unknown): T {
  const [value, setValue] = useState<T>(getValue());

  useEffect(() => {
    const newValue = getValue();
    if (!isEqual(value, newValue)) {
      setValue(newValue);
    }
  }, [deps, getValue, value]);

  return value;
}

export default useCustomMemo;
