import gql from 'graphql-tag';

export default gql`
  mutation unLockInstance($input: UnlockMemberInput) {
    unlockMember(input: $input) {
      mId
      mRefId
      locked
    }
  }
`;

// mutation unLockInstance($input: UnlockInstanceInput) {
//   unlockInstance(input: $input) {
//     mId
//     mRefId
//     mTitle
//     mAvatarKey
//     mThumbnailKey
//     mStoryId
//     mCreatedAt
//     mType
//     mState
//     mPublishingAt
//     mUpdatedAt
//     mContentKey
//     mDefaultContentKey
//     mThumbnailUrl
//     locked
//     mProperties {
//       __typename
//       ... on PlatformType {
//         platform
//         account {
//           accountId
//           accountRefId
//           accountUrl
//           accountLogo
//           accountTitle
//         }
//       }
//     }
//     mMetaData {
//       key
//       value
//     }
//   }
// }
