/* eslint-disable import/no-extraneous-dependencies */
import { Text } from 'slate';
import words from 'lodash/words';
import textColors from 'components/editor/constants/textColors';

/**
 * Finds non-cplored words given SlateJS document
 *
 * @param {Object[]} document SlateJS document instance
 * @returns {String[]} Colored words
 */

const getWords = (document, ignoreColoredText = true) => {
  const nodes = filterNonColoredNodes(document, ignoreColoredText, []);
  const text = nodes.join();

  return words(text);
};

const [white] = textColors;

const whiteColors = [white, '#fefefe'];

const filterNonColoredNodes = (nodes, ignoreColoredText = true, initialValue = []) =>
  nodes
    ? nodes.reduce((accumulator, node) => {
        const { color, text, children } = node;
        if (Text.isText(node) && (!ignoreColoredText || !color || whiteColors.includes(color))) {
          return [...accumulator, text.trim()];
        }

        if (!children) return accumulator;

        return filterNonColoredNodes(children, ignoreColoredText, accumulator);
      }, initialValue)
    : [];

export default getWords;
