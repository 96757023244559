import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Storage } from '@aws-amplify/storage';

import { EditorValue } from 'types';
import { replaceTab } from 'utils/content/contentUtil';

const useTextStorage = (key: string, skip = false) => {
  const [data, setData] = useState<EditorValue | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const memoizedKey = useMemo(() => key ?? '', [key]);

  const isSubscribed = useRef<string | null>(null);

  const refetch = useCallback(() => {
    setShouldRefetch(true);
  }, []);

  const getContent = useCallback((contentKey: string) => {
    setLoading(true);
    return Storage.get(contentKey, {
      customPrefix: { public: '' },
    })
      .then((result) => fetch(result))
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
        return response.json();
      })
      .then((jsonResponse: string) => {
        if (isSubscribed.current === contentKey) {
          setData(replaceTab(jsonResponse as unknown as EditorValue));
        }
      })
      .catch((err: Error) => {
        if (err.message.includes('<Error><Code>NoSuchKey</Code>')) {
          setError('No content found');
        } else {
          setError(err.message || 'An error occurred');
        }
        setData(null);
      })
      .finally(() => {
        setLoading(false);
        setShouldRefetch(false);
      });
  }, []);

  useEffect(() => {
    if (loading && shouldRefetch) {
      setShouldRefetch(false);
    }
  }, [loading, shouldRefetch]);

  // sets data to null when key changes
  useEffect(() => () => setData(null), [memoizedKey]);

  useEffect(() => {
    isSubscribed.current = memoizedKey;
    if ((shouldRefetch || !skip) && memoizedKey) {
      getContent(memoizedKey).then(
        () => {},
        () => {},
      );
    }

    return () => {
      isSubscribed.current = null;
    };
  }, [memoizedKey, skip, shouldRefetch, getContent]);

  return { error, loading, data, refetch };
};

export default useTextStorage;
