import { useState } from 'react';
import styled from '@emotion/styled/macro';

import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text';
import useCustomMemo from 'hooks/useCustomMemo';
import useSettingsValue from 'hooks/useSettingsValue';
import { Box } from 'layouts/box/Box';

import { useChangedUserSettings } from '../../../../../atomsTs';

export const PreferencesWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const TitleWrapper = styled('div')`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const editorLockInteractionKey = 'user.lockByDoubleClick';

const UserPreferences = () => {
  const [getSettingsValue] = useSettingsValue();
  const isDoubleClickOn = useCustomMemo(
    () =>
      getSettingsValue?.(editorLockInteractionKey, 'lockByDoubleClick', 'false')?.toString() ===
      'true',
    [getSettingsValue],
  );

  const [tempState, setTempState] = useState(isDoubleClickOn);

  const [changedUserSettings, setChangedUserSettings] = useChangedUserSettings();

  const onChangeEditorLockInteraction = (newState: boolean) => {
    setTempState(newState);
    const changedSetting = changedUserSettings.find((x) => x.key === editorLockInteractionKey);
    if (changedSetting) {
      if (changedSetting?.originalValue === newState) {
        setChangedUserSettings(changedUserSettings.filter((x) => x.key !== changedSetting.key));
      } else {
        changedSetting.value = newState;
      }
    } else {
      setChangedUserSettings([
        ...changedUserSettings,
        {
          key: editorLockInteractionKey,
          value: newState,
          originalValue: isDoubleClickOn,
        },
      ]);
    }
  };

  return (
    <PreferencesWrapper container>
      <TitleWrapper>
        <Text variant="h6" color="highEmphasis">
          User Preferences
        </Text>
      </TitleWrapper>
      <Scrollbar>
        <Box
          container
          flex="1"
          flexDirection="column"
          gap="1rem"
          padding="1rem"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Box
            container
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="0.5rem"
          >
            <CheckboxWithLabel
              key={editorLockInteractionKey}
              label="Double click to lock editor"
              selected={tempState}
              onClick={onChangeEditorLockInteraction}
            />
          </Box>
        </Box>
      </Scrollbar>
    </PreferencesWrapper>
  );
};

export default UserPreferences;
