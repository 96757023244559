import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import Divider from 'components/divider';
import useStyles from './warning-styles';

const getLegends = (type, name) => {
  const legends = {
    folder: {
      headerText: 'Template folder already exists',
      leftButtonText: 'Ok',
      leftButtonStyle: 'okButton',
      primaryText: `An Template Folder named “${name}” already exists in this location.`,
      secondaryText: 'Please go back and give the folder a different name.',
      actionButtonText: null,
      showActionButton: false,
    },
    template: {
      headerText: 'Overwrite existing Template?',
      leftButtonText: 'Cancel',
      leftButtonStyle: 'cancelButton',
      primaryText: `An Instance Template named “${name}” already exists in this location.`,
      secondaryText: 'Do you wish to overwrite existing template?',
      actionButtonText: 'Overwrite',
      showActionButton: true,
    },
    templateOverride: {
      headerText: 'Overwriting existing Template',
      leftButtonText: 'Cancel',
      leftButtonStyle: 'cancelButton',
      primaryText: `Do you wish to overwrite existing template?`,
      secondaryText: '',
      actionButtonText: 'Overwrite',
      showActionButton: true,
    },
    unlockInstance: {
      headerText: 'Unlock Instance',
      leftButtonText: 'Cancel',
      leftButtonStyle: 'cancelButton',
      primaryText: `If you unlock this instance, 
      ${name} will lose access to editing of this instance. 
      ${name}'s work will of course be saved.`,
      secondaryText: null,
      actionButtonText: 'Unlock',
      showActionButton: true,
    },
    instanceLocked: {
      headerText: 'Instance is locked',
      leftButtonText: 'Close',
      leftButtonStyle: 'okButton',
      primaryText: `${name} is editing this instance. 
      The instance must be unlocked in order to proceed.`,
      secondaryText: null,
      actionButtonText: null,
      showActionButton: false,
    },
    storyLocked: {
      headerText: 'Story is locked',
      leftButtonText: 'Close',
      leftButtonStyle: 'okButton',
      primaryText: `${name} is editing this story. 
      The story must be unlocked in order to proceed.`,
      secondaryText: null,
      actionButtonText: null,
      showActionButton: false,
    },
  };
  return legends[type];
};

const Warning = (props) => {
  const { title, onClose, onConfirm, variant } = props;
  const {
    headerText,
    leftButtonText,
    leftButtonStyle,
    primaryText,
    secondaryText,
    actionButtonText,
    showActionButton,
  } = getLegends(variant, title);
  const classes = useStyles({ secondaryText });

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography classes={{ root: classes.headerText }}>{headerText}</Typography>
        <Divider />
      </div>
      <div className={classes.body}>
        <Typography classes={{ root: classes.bodyText }}>{primaryText}</Typography>
        {secondaryText && (
          <Typography classes={{ root: classes.bodyText }} className={classes.secondBodyText}>
            {secondaryText}
          </Typography>
        )}
      </div>
      <div className={classes.footer}>
        <Divider />
        <div className={classes.buttonContainer}>
          {showActionButton && (
            <Button classes={{ root: classes.overwriteButton }} onClick={handleConfirm}>
              {actionButtonText}
            </Button>
          )}
          <Button classes={{ root: classes[leftButtonStyle] }} onClick={onClose}>
            {leftButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

Warning.propTypes = {
  /** Name of the folder/template that already exists */
  title: PropTypes.string,
  /** Callback to be invoked when clicking Ok button */
  onClose: PropTypes.func,
  /** Callback to be invoked when clicking Cancel button */
  onConfirm: PropTypes.func,
  /** Variant of warning dialog */
  variant: PropTypes.oneOf(['folder', 'template', 'unlockInstance', 'instanceLocked']),
};

Warning.defaultProps = {
  title: '',
  onClose: () => {},
  onConfirm: () => {},
  variant: 'folder',
};

export default Warning;
