/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { capitalize, keyBy } from 'lodash';

import Radio from 'components/buttons/radioButton';
import Dialog from 'components/dialogs/DialogBuilder';
import Infobar from 'components/infobar/Infobar';
import { StyledOptionWrapper, StyledPopper } from 'components/mdfEditor/fields/choice/styled';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { Box, HStack } from 'layouts/box/Box';
import { Alternative, Mdf } from 'types/graphqlTypes';
import { getPlatformIcon } from 'utils/instance/platform';

const SchemaTypes = ['custom', 'subtype', 'instance'] as const;
const SchemaTypeLabel: Record<SchemaType, string> = {
  custom: 'Custom schema',
  instance: 'Instance schema',
  subtype: 'Sub type',
};
const SchemaTypeInfo: Record<SchemaType, string> = {
  custom:
    'A custom schema can be connected to order forms, slash commands and other configurations.',
  instance:
    'Add a new schema for a specific instance platform. If a schema for a platform already exists, it will not appear here.',
  subtype:
    'A sub type is a set of fields, that can be configured that will appear based on a user choice.',
};

interface Props {
  open: boolean;
  creatingMdf: boolean;
  schemaType: SchemaType;
  existingInstanceMdfs: Mdf[];
  setSchemaType: (val: SchemaType) => void;
  setOpen: (val: boolean) => void;
  onCreate: (val: { label: string; schemaType: SchemaType; instanceId?: string }) => void;
}

export type SchemaType = (typeof SchemaTypes)[number];

export default function CreateSchemaDialog({
  existingInstanceMdfs,
  open,
  setOpen,
  creatingMdf,
  onCreate,
  schemaType,
  setSchemaType,
}: Readonly<Props>) {
  const { platformVariants } = useGetPlatforms();
  const [label, setLabel] = useState('');
  const [selectedInstance, setSelectedInstance] = useState<Alternative | null>(null);

  const derivedInstanceId = useMemo(() => {
    return schemaType === 'instance' ? selectedInstance?.id ?? null : null;
  }, [selectedInstance, schemaType]);

  const derivedLabel = useMemo(() => {
    if (derivedInstanceId && platformVariants[derivedInstanceId]) {
      if (derivedInstanceId === 'linear') return 'Linear';
      if (derivedInstanceId === 'linear-audio') return 'Audio';
      return capitalize(platformVariants[derivedInstanceId]?.title ?? label);
    }
    return label;
  }, [label, derivedInstanceId, platformVariants]);

  const onConfirm = useCallback(() => {
    if (schemaType === 'instance' && derivedInstanceId) {
      onCreate({
        label: derivedLabel,
        schemaType,
        instanceId: derivedInstanceId,
      });
    } else if (schemaType !== 'instance') {
      onCreate({
        label: derivedLabel,
        schemaType,
      });
    }
  }, [derivedLabel, derivedInstanceId, schemaType]);

  useEffect(() => {
    if (!open) {
      setLabel('');
      setSchemaType('custom');
      setSelectedInstance(null);
    }
  }, [open]);

  const existingMdfsById = useMemo(() => {
    return keyBy(existingInstanceMdfs, (mdf) => mdf.id);
  }, [existingInstanceMdfs]);

  const instanceOptions = useMemo(() => {
    const options: Alternative[] = [];
    for (const platform in platformVariants) {
      const variant = platformVariants[platform];
      options.push({
        id: variant.id,
        value: variant.id,
        icon: variant.icon,
        label: variant.title,
      });
    }
    return options.filter((o) => existingMdfsById[o.id] === undefined);
  }, [platformVariants, existingInstanceMdfs]);

  const renderInput = (params: object) => (
    <StyledTextField {...params} variant="filled" placeholder="Select instance" />
  );

  const renderOption = (alternative: Alternative) => {
    const Icon = getPlatformIcon(alternative.icon ?? alternative.id);
    return (
      <StyledOptionWrapper $selected={derivedInstanceId === alternative.value}>
        <HStack gap="6px" alignItems="center">
          <Icon style={{ width: '16px' }} />{' '}
          <div>{alternative.label ?? alternative.value ?? 'Invalid option!'}</div>
        </HStack>
      </StyledOptionWrapper>
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>Create new schema</Dialog.Header>
      <Dialog.Body>
        <Box container gap="4px" justifyContent="start">
          {SchemaTypes.map((type) => (
            <Box container key={type}>
              <Radio selected={schemaType === type} onClick={() => setSchemaType(type)} />
              <Text
                variant="caption"
                color={schemaType === type ? 'highEmphasis' : 'mediumEmphasis'}
                onClick={() => setSchemaType(type)}
                style={{ cursor: 'pointer' }}
              >
                {SchemaTypeLabel[type]}
              </Text>
            </Box>
          ))}
        </Box>
        <Box margin="10px 0">
          {schemaType === 'instance' ? (
            <Autocomplete
              openOnFocus
              disableClearable
              fullWidth
              blurOnSelect
              noOptionsText="No options available"
              selectOnFocus={false}
              options={instanceOptions}
              value={selectedInstance ?? undefined}
              getOptionSelected={(alternative: Alternative, v: Alternative) => {
                if (!v) return false;
                return alternative.value === v.value;
              }}
              onChange={(_ev, alternative) => setSelectedInstance(alternative)}
              renderInput={renderInput}
              renderOption={renderOption}
              PopperComponent={StyledPopper}
              getOptionLabel={(alternative) => alternative?.label ?? alternative?.value}
            />
          ) : (
            <StyledTextField
              error={derivedLabel.length < 1}
              helperText={derivedLabel.length < 1 ? 'Label required' : ''}
              autoFocus
              variant="filled"
              fullWidth
              placeholder="Schema label"
              value={derivedLabel}
              onChange={(event) => setLabel(event.target.value)}
            />
          )}
        </Box>

        <Infobar>{SchemaTypeInfo[schemaType]}</Infobar>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          onConfirm={onConfirm}
          label="Confirm"
          disabled={derivedLabel.length < 1 || creatingMdf}
          loading={creatingMdf}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
