import { ChangeEvent, useCallback, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import Chip from 'components/chip';
import LoadingIndicator from 'components/loadingIndicator';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Tooltip from 'components/tooltip';
import { useSearchMembers } from 'hooks/useSearchMembers';
import { SearchItemTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { getParseMetadata } from 'utils/getParsedMetadata';

import { ChipWrapper, StyledOption, StyledOptionWrapper, StyledPopper } from './styled';

interface MemberAutoCompleteProps {
  selectedMembers: AssignedMember[];
  allMembers: AssignedMember[];
  setSelectedMembers: (values: AssignedMember[]) => void;
  onRemove: (value: AssignedMember, index: number) => void;
  onClose: () => void;
  autoFocus: boolean;
  selectOnFocus: boolean;
  placeholderText: string;
  noOptionsText: string;
  singleChoice: boolean;
  disableClearable: boolean;
  showChips: boolean;
  doCallApi: boolean;
}

export default function MemberAutoComplete({
  selectedMembers = [],
  allMembers,
  setSelectedMembers = () => {},
  onRemove,
  onClose = () => {},
  autoFocus = false,
  selectOnFocus = false,
  placeholderText = 'Type to search, or press down to browse',
  noOptionsText = 'None found, or all available resources already added',
  singleChoice = true,
  disableClearable = true,
  showChips = true,
  doCallApi = false,
}: Readonly<MemberAutoCompleteProps>) {
  const [searchString, setSearchString] = useState('');
  const { items: searchResults, loading } = useSearchMembers({
    inputs: {
      mTypes: [SearchItemTypeEnum.contact],
    },
    searchString: searchString,
    skip: !doCallApi,
  });

  const renderOption = (member: AssignedMember) => {
    return (
      <StyledOptionWrapper>
        <Avatar
          variant={member.mType as AvatarVariant}
          imageKey={member.mAvatarKey}
          size={24}
          title={member.mTitle}
        />
        <StyledOption className="styled-option" variant="listItemLabel" color="highEmphasis">
          {member.mTitle}
        </StyledOption>
      </StyledOptionWrapper>
    );
  };

  const renderTags = (value: AssignedMember[]) =>
    value.map((member, index) => (
      <Tooltip title={member.mTitle} key={member.mId}>
        <ChipWrapper>
          <Chip
            variant={member?.mType as AvatarVariant}
            onDelete={() => {
              onRemove(member, index);
            }}
            label={member.mTitle}
            avatarKey={member.mAvatarKey}
          />
        </ChipWrapper>
      </Tooltip>
    ));

  const onUpdateInput = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchString(e.target.value),
    [],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <StyledTextField
          {...params}
          autoFocus={autoFocus}
          variant="filled"
          placeholder={placeholderText}
          onChange={onUpdateInput}
        />
      );
    },
    [autoFocus, placeholderText, onUpdateInput],
  );

  const onChange = (newValue: AssignedMember[] | AssignedMember | null) => {
    const updatedMembers = newValue ?? [];
    setSelectedMembers(Array.isArray(updatedMembers) ? updatedMembers : [updatedMembers]);
  };

  const getFilteredSearchResults = useCallback(() => {
    return searchResults?.filter((member) => {
      return !getParseMetadata(member?.metadata)?.notListed;
    }) as AssignedMember[];
  }, [searchResults]);

  const determineOptions = useCallback(
    () => (doCallApi ? getFilteredSearchResults() : allMembers),
    [doCallApi, allMembers, getFilteredSearchResults],
  );

  return (
    <Autocomplete
      noOptionsText={noOptionsText}
      fullWidth
      selectOnFocus={selectOnFocus}
      filterSelectedOptions
      multiple={!singleChoice}
      options={determineOptions()}
      openOnFocus
      clearOnBlur={false}
      disableClearable={disableClearable}
      forcePopupIcon={false}
      onClose={onClose}
      value={selectedMembers}
      getOptionSelected={(option, value) => option.mId === value.mId}
      PopperComponent={StyledPopper}
      onChange={(_ev, value) => onChange(value)}
      renderTags={showChips ? renderTags : () => null}
      renderInput={renderInput}
      renderOption={renderOption}
      groupBy={(option) => option.mType}
      getOptionLabel={(option) => option?.mTitle ?? ''}
      loading={loading}
      loadingText={<LoadingIndicator height={20} width={20} />}
    />
  );
}
