import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { differenceInCalendarDays } from 'date-fns';

import { ReactComponent as Archive } from 'assets/icons/systemicons/archive.svg';
import { ReactComponent as Photo } from 'assets/icons/systemicons/coverPhoto.svg';
import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as Unlock } from 'assets/icons/systemicons/padlock_off.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/systemicons/print.svg';
import { ReactComponent as Refresh } from 'assets/icons/systemicons/refresh.svg';
import { ReactComponent as History } from 'assets/icons/systemicons/time.svg';
import { ReactComponent as Asset } from 'assets/icons/systemicons/upload.svg';
import { IconButton } from 'components/buttons';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Popover from 'components/popover/Popover';
import Switch from 'components/switch/Switch';
import ToggleButtons from 'components/toggleButtons';
import UploadMediaInput from 'components/uploadMediaInput';
import ContentPrint from 'features/print/ContentPrint';
import VersionHistory from 'features/versionHistory';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useGetArchiveTime from 'hooks/useGetArchiveTime';
import useSetStorySync from 'hooks/useSetStorySync';
import useSettingsValue from 'hooks/useSettingsValue';
import useStoryContent from 'hooks/useStoryContent';
import useUnlockInstance from 'hooks/useUnlockInstance';
import memberTypes from 'operations/memberTypes';
import UPDATE_STORY from 'operations/mutations/updateStory';
import { useStoryView } from 'store';
import { EditorValue, Story } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import fontSizeList from 'utils/constants/fontSizeList';
import { getUserIdFromLockedId } from 'utils/lock/lockTokenV2';

import useStyles from '../../story/components/toolbar/ellipsisDropdown/ellipsisDropdown-styles';
import {
  useIsDescriptionShown,
  useIsTimelineShown,
  useStoryEditorFontSize,
} from '../store/toolbar';

import { ItemsWrapper } from './styled';

const { ARCHIVED_STORY, ARCHIVED_RESTRICTED_STORY } = memberTypes;

interface DropdownProps {
  story: Story;
  isPitch: boolean;
  canUpdateStory: boolean;
  isDescriptionSettingsHidden: boolean;
}

const Dropdown = ({
  story,
  isPitch,
  canUpdateStory,
  isDescriptionSettingsHidden,
}: DropdownProps) => {
  const [updateStory] = useMutation(UPDATE_STORY);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [storyView, setStoryView] = useStoryView();
  const [getAutoArchiveTime] = useGetArchiveTime();
  const [editorFontSize, setEditorFontSize] = useStoryEditorFontSize();
  const [getSettingsValue] = useSettingsValue();
  const [checkUserRight] = useCheckUserRight();

  const [setSync] = useSetStorySync();
  const [unlockInstance] = useUnlockInstance();

  const { isSavingContent, onRestoreVersion, checkVersionRestorability } = useStoryContent(
    story,
    canUpdateStory,
  );

  const accessStoryV1 = checkUserRight('feature', 'access-story-v1');

  //default will be removed later
  const liveUSyncProviderId = getSettingsValue('app.syncProvider.liveU.id') as string;
  const liveUParentProviderId = getSettingsValue(
    'app.syncProvider.liveU.parentProviderId',
  ) as string;

  const pitchSyncEnabled = getSettingsValue('app.enabledPitchSync') === 'true';
  const syncEnabled = isPitch ? pitchSyncEnabled : true;

  const liveUProviderEnabled =
    Array.isArray(story?.mSyncProviders) &&
    story?.mSyncProviders.find(
      (p) => p.mId === liveUSyncProviderId || p.mId === liveUParentProviderId,
    ) &&
    liveUSyncProviderId;

  const uploadComplete = (val: boolean) => {
    if (val === true) {
      setAnchorEl(null);
    }
  };

  const [isTimelineShown, setIsTimelineShown] = useIsTimelineShown();
  const toggleTimeline = () => setIsTimelineShown(!isTimelineShown);

  const [isDescriptionShown, setIsDescriptionShown] = useIsDescriptionShown();
  const toggleDescription = () => setIsDescriptionShown(!isDescriptionShown);

  const getActiveProviders = (activeProviders: string) => {
    const providers = (activeProviders ? [activeProviders] : []).filter(
      (m) => m !== liveUSyncProviderId,
    );
    return providers.length ? providers : [];
  };

  const [syncActive, setSyncActive] = useState(getActiveProviders(story?.mSyncActive ?? ''));
  // think about more generic solution
  const [isLiveUSyncActive, setIsLiveUSyncActive] = useState(
    story?.mSyncActive?.includes(liveUSyncProviderId),
  );

  const [archiveTime, setArchiveTime] = useState<number | null | undefined>(story?.ttl);

  useEffect(() => {
    if (story?.mSyncActive) {
      setSyncActive(getActiveProviders(story.mSyncActive));
      setIsLiveUSyncActive(story.mSyncActive?.includes(liveUSyncProviderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [story?.mSyncActive]);

  useEffect(() => {
    setArchiveTime(story?.ttl ?? null);
  }, [story?.ttl]);

  if (!story) return null;

  const handleSynchronizeStateChange = async (val: string[]) => {
    const currentSyncState = new Set(story.mSyncActive);
    let deactivated = false;
    if (val.length > 0) {
      val.forEach((v) => currentSyncState.delete(v));
      deactivated = true;
    } else {
      currentSyncState.add('*');
    }
    const providers = Array.from(currentSyncState);
    setSyncActive(providers);
    await setSync(story.mId, providers, deactivated);
  };

  const handleLiveUSynchronizeStateChange = async () => {
    const currentSyncState = new Set(story.mSyncActive);
    let deactivated = false;
    if (isLiveUSyncActive) {
      currentSyncState.delete(liveUSyncProviderId);
      deactivated = true;
    } else {
      currentSyncState.add(liveUSyncProviderId);
    }

    await setSync(story.mId, Array.from(currentSyncState), deactivated);
  };

  const handleForceUnlock = () => {
    setAnchorEl(null);
    void unlockInstance(story.mId);
  };

  const handleHistory = () => {
    setHistoryDialogOpen(true);
  };

  const onPrintContent = () => {
    setPrintDialogOpen(true);
  };

  const onClosePrintDialog = () => {
    setPrintDialogOpen(false);
  };

  const handleRestoreVersion = async (content: EditorValue) => {
    await onRestoreVersion(content);
    setHistoryDialogOpen(false);
    setAnchorEl(null);
  };

  const toggleStoryView = () => {
    setStoryView(storyView === 'v1' ? 'v2' : 'v1');
  };

  const handleAutoArchiveChange = async (isAutoArchive: boolean) => {
    const changedArchiveTime =
      isAutoArchive && story?.mPublishingAt ? getAutoArchiveTime(story?.mPublishingAt) : null;
    setArchiveTime(changedArchiveTime);
    await updateStory({
      variables: {
        input: {
          mId: story?.mId,
          ttl: changedArchiveTime,
        },
      },
    });
  };

  const getDaysLeftToArchive = () => {
    const archiveTimestamp = story.ttl ?? getAutoArchiveTime(story.mPublishingAt ?? '');
    const daysLeftToArchive = differenceInCalendarDays(
      new Date(archiveTimestamp! * 1000),
      new Date(),
    );
    return daysLeftToArchive;
  };

  const listItems = [
    accessStoryV1 && (
      <ListItem
        key="story-view-switch"
        maxHeight={36}
        text={`Use ${story?.mType?.includes('story') ? 'Story' : 'Pitch'} View V2`}
        iconChild={
          <Switch selected={storyView === 'v2'} onClick={toggleStoryView} disabled={undefined} />
        }
      />
    ),
    <div className={classes.fontSizeContainer} key="fontSize">
      <ToggleButtons
        list={fontSizeList}
        label="Editor Font Size"
        selectedValue={editorFontSize}
        onChange={setEditorFontSize}
      />
    </div>,
    <ListItem
      key="show-timeline"
      maxHeight={36}
      text="Show Story Timeline"
      disabled={isPitch}
      iconChild={
        <Switch
          selected={!isPitch && isTimelineShown}
          onClick={toggleTimeline}
          disabled={isPitch}
        />
      }
      excludeDivider={accessStoryV1}
    />,
    <ListItem
      key="show-description"
      maxHeight={36}
      text="Show Story Description"
      disabled={isDescriptionSettingsHidden}
      iconChild={
        <Switch
          selected={!isDescriptionSettingsHidden && isDescriptionShown}
          onClick={toggleDescription}
          disabled={isDescriptionSettingsHidden}
        />
      }
    />,
    <ListItem
      key="photo"
      maxHeight={36}
      text="Upload Cover Photo"
      firstChild={<Photo />}
      iconChild={
        <UploadMediaInput
          mId={story.mId}
          coverPhoto
          onComplete={uploadComplete}
          memberType={MemberTypeEnum.Story}
          inputRef={undefined}
        />
      }
      disabled={!canUpdateStory}
      excludeDivider
    />,
    <ListItem
      key="assets"
      maxHeight={36}
      text="Upload Media Assets"
      firstChild={<Asset />}
      iconChild={
        <UploadMediaInput
          mId={story?.mId ?? ''}
          onComplete={uploadComplete}
          memberType={MemberTypeEnum.Story}
          inputRef={undefined}
        />
      }
      disabled={!canUpdateStory}
    />,
    <ListItem
      key="synchronize"
      maxHeight={36}
      text="Synchronize"
      firstChild={<Refresh />}
      iconChild={
        <Switch
          selected={syncActive.length > 0}
          onClick={() => handleSynchronizeStateChange(syncActive)}
          disabled={!syncEnabled}
        />
      }
      disabled={!canUpdateStory || !syncEnabled}
      excludeDivider
    />,
    liveUProviderEnabled && (
      <ListItem
        key="liveu-synchronize"
        maxHeight={36}
        text="Sync with LiveU"
        firstChild={<Refresh />}
        iconChild={
          <Switch
            selected={isLiveUSyncActive}
            onClick={() => handleLiveUSynchronizeStateChange()}
            disabled={!syncEnabled}
          />
        }
        disabled={!canUpdateStory || !syncEnabled}
        excludeDivider
      />
    ),
    <ListItem
      key="auto-archive-story"
      maxHeight={36}
      text="Auto Archive Story"
      firstChild={<Archive />}
      textLabel="(Beta)"
      disabled={isPitch ?? [ARCHIVED_STORY, ARCHIVED_RESTRICTED_STORY].includes(story?.mType)}
      iconChild={
        <>
          {!isPitch && archiveTime !== null && (
            <span className={classes.switchText}>
              Days till <br />
              archive: {getDaysLeftToArchive()}
            </span>
          )}
          <Switch
            selected={!isPitch && archiveTime !== null}
            onClick={handleAutoArchiveChange}
            disabled={
              !canUpdateStory ||
              isPitch ||
              [ARCHIVED_STORY, ARCHIVED_RESTRICTED_STORY].includes(story?.mType)
            }
          />
        </>
      }
    />,
    <ListItem
      key="forceunlock"
      maxHeight={36}
      text="Force Unlock"
      firstChild={<Unlock />}
      onClick={handleForceUnlock}
      disabled={!canUpdateStory}
      excludeDivider
    />,
    <ListItem
      key="versionHistory"
      maxHeight={36}
      text="Version History"
      firstChild={<History />}
      onClick={handleHistory}
      disabled={!canUpdateStory}
      excludeDivider
    />,
    <ListItem
      key="printContent"
      maxHeight={36}
      text="Print Content"
      firstChild={<PrintIcon />}
      onClick={onPrintContent}
      excludeDivider
    />,
  ];

  return (
    <div className={classes.wrapper}>
      {historyDialogOpen && canUpdateStory && (
        <VersionHistory
          title={story.mTitle}
          id={story.mId}
          open={historyDialogOpen}
          onCancel={() => setHistoryDialogOpen(false)}
          onOk={handleRestoreVersion}
          checkVersionRestorability={checkVersionRestorability}
          isSavingContent={isSavingContent}
          versionElement={MemberTypeEnum.Story}
          lockedByUser={getUserIdFromLockedId(story.locked ?? '') ?? undefined}
        />
      )}
      {printDialogOpen && story && (
        <ContentPrint isDialogOpen={true} onCloseDialog={onClosePrintDialog} story={story} />
      )}
      <Popover anchorEl={anchorEl} onClose={() => setAnchorEl(null)} position="bottom">
        <ItemsWrapper>{listItems}</ItemsWrapper>
      </Popover>
      <IconButton
        dark
        title="More options"
        usage="text"
        size={32}
        iconSize={18}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
      >
        <More className="skipOverride" />
      </IconButton>
    </div>
  );
};

export default Dropdown;
